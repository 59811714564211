<template>
    <section class="partners flex">
        <PartnersArticle/>
        <PartnersListDesktop class="desktop"/>
        <PartnersListMobile class="mobile"/>
    </section>
</template>

<script>
import PartnersArticle from '@/components/sub/partners/PartnersArticle.vue';
import PartnersListDesktop from '@/components/sub/partners/PartnersListDesktop.vue';
import PartnersListMobile from '@/components/sub/partners/PartnersListMobile.vue';

export default {
    name: 'Partners',

    components: {
        PartnersArticle, PartnersListDesktop, PartnersListMobile
    }
}
</script>

<style scoped>
.partners {
    margin-top: 70px;
    flex-direction: column;
    background-color: var(--couleurFondArticle-);
    padding-bottom: 20px;
}

.desktop {
    display: grid;
}

.mobile {
    display: none;
}

@media screen and (max-width: 768px) {
  .desktop {
    display: none;
}

.mobile {
    display: block;
}  
}
</style>