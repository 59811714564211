<template>
    <div class="flex banner-services">
        <h2>Nos services</h2>
        <div class="flex banner-presentation-service">
            <img src="../../../../assets/images/logo/services/electricite_64px.png" alt="electricite">
            <div class="banner-description-service">
                <h3>Installation</h3>
                <p>Mise aux normes de votre habitation</p>
            </div>
        </div>
        <div class="flex banner-presentation-service">
            <img src="../../../../assets/images/logo/services/depannage_64px.png" alt="depannage">
            <div class="banner-description-service">
                <h3>Dépannage</h3>
                <p>Dépannage à domicile</p>
            </div>
        </div>
        <div class="flex banner-presentation-service">
            <img src="../../../../assets/images/logo/services/domotique_64px.png" alt="domotique">
            <div class="banner-description-service">
                <h3>Domotique</h3>
                <p>Domotique à la pointe de la technologie</p>
            </div>
        </div>
        <div class="flex banner-presentation-service">
            <img src="../../../../assets/images/logo/services/ventilateur_64px.png" alt="ventilation">
            <div class="banner-description-service">
                <h3>VMC</h3>
                <p>Spécialisé dans l'installation de VMC</p>
            </div>
        </div>
        <div class="div-link">
            <a class="link-to-services" href="/services">Voir tous nos services...<span class="arrow">🡺</span></a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BannerServices',
}
</script>

<style scoped>
.banner-services {
    flex-direction: column;
    row-gap: 30px;
    align-items: flex-start;
}

.banner-services h2 {
    font-size: 2rem;;
}

.banner-presentation-service {
    justify-content: flex-start;
    column-gap: 20px;
}

.banner-description-service h3 {
    font-size: 1.2rem;
}

.banner-description-service p {
    color: #f1f1f1;
}

.link-to-services {
    text-decoration: none;
    color: var(--couleurFondNoir-);
    font-size: 1.3rem;
    font-weight: bolder;
}

.arrow {
    margin-left: 15px;
}

.link-to-services:hover {
    font-style: italic;
}

@media screen and (max-width: 800px) {
.div-link {
    align-self: center;
}
}
</style>