<template>
    <article class="article about-us">
        <h1 class="titre-article">Qui sommes nous...</h1>

        <p>
            Depuis 2017 l'entreprise <strong class="gras">PRO ELEC 29</strong> située à Saint-Renan près de Brest dans le département du Finistère (29), est composée d'employés qualifiés spécialisés dans l'électricité générale.
        </p>
        <p>
            <strong class="gras">PRO ELEC 29</strong> met à votre service son expérience unique pour vos logements et vos locaux professionnels.
            Avec nos spécialistes formés et notre équipement moderne, nous travaillons au plus haut niveau pour garantir votre satisfaction et parfaire votre domicile.
        </p>
        <p>
            Parcourez nos fournisseurs et laissez-vous convaincre.
            <strong class="gras">PRO ELEC 29</strong> remet sur pied tous vos équipements éléctriques. Nous vous aidons à régler vos problèmes et satisfaisons toutes vos attentes grâce à nos conseils professionnels, nos horaires de travail variables et nos délais court lors des prises de rendez-vous.
        </p>
        <p>
            <a class="gras lien-paragraphe" href="/contact">Contactez-nous</a> sans plus attendre pour de plus amples informations sur nos services.
        </p>
    </article>
</template>

<script>
export default {
    name: 'AboutUs',
}
</script>

<style scoped>
.about-us {
    padding: 50px 45px 40px 80px;
}

.titre-article {
    position: relative;
    margin-bottom: 80px;
}

.article p:last-child {
    margin-top: 40px;
}

@media screen and (max-width: 800px) {
.about-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px 40px 10px;
}
}
</style>