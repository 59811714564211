<template>
  <div class="fb-page" data-href="https://www.facebook.com/PRO-ELEC-29-111952791301996" data-width="500" data-height="600" data-tabs="timeline,events" data-small-header="false" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="true">
      <blockquote cite="https://www.facebook.com/PRO-ELEC-29-111952791301996" class="fb-xfbml-parse-ignore">
      <a href="https://www.facebook.com/PRO-ELEC-29-111952791301996">PRO ELEC 29</a>
      </blockquote>
  </div>
</template>

<script>
// data-width="550" data-height="600" 
export default {
    name: 'Facebook'
}
</script>

<style scoped>
.fb-page {
  width: 500px;
}

@media screen and (max-width: 1090px) {
  .fb-page {
    width: 98vw;
    height: auto;
  }
}
</style>