<template>
    <section class="flex bilan">
        <div class="years-etablished">
            <p class="main-info">{{ yearsOld }}</p>
            <p class="object-info">ANS D'EXISTENCE</p>
        </div>

        <div class="satisfaction">
            <p class="main-info">25 kms</p>
            <p class="object-info">ZONE D'INTERVENTION</p>
        </div>
    </section>
</template>

<script>
export default { 
    name: 'Bilan',
    computed: {
        yearsOld() {
            return this.$store.state.yearsEtablished;
        }
    }
}
</script>

<style scoped>
.bilan {
    justify-content: space-around;
}

.years-etablished,
.satisfaction {
    flex-grow: 1;
    text-align: center;
    padding: 40px 0px;
    line-height: 1.4;
}

.years-etablished {
    color: var(--couleurFondNoir-);
    background-color: var(--couleurFondJaune-);
}

.satisfaction {
    color: var(--couleurFondJaune-);
    background-color: var(--couleurFondNoir-);
}

.main-info {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 2.4rem;
    font-weight: bolder;
}

.object-info {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.92rem;
    font-weight: bolder;
}

</style>