<template>
    <div class="banner-logo flex" title="Photo de Josh Boot - Unsplash.com">
        <h1 class="titre">PRO ELEC 29</h1>
        <p>Une équipe de professionnel à votre service pour vous aider à réaliser tous vos projets.</p>
    </div>
</template>

<script>
export default {
    name: 'BannerLogo',
}
</script>

<style scoped>
.banner-logo {
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    color: var(--couleurFondBlanc-);
    padding-bottom: 100px;
    padding-left: 80px;
    background: url('/assets/images/fond_banniere_home.jpg');
    background-size: cover;
    background-position: left 80%;
}

.titre {
    position: relative;
    font-size: 3.8rem;
}

.banner-logo p {
    font-size: 1.5rem;
}

.titre::before {
    content: "";
    position: absolute;
    display: inline-block;
    height: 70px;
    width: 10px;
    left: -25px;
    background: var(--couleurFondJaune-);
}

@media screen and (max-width: 800px) {
.banner-logo {
    padding-bottom: 80px;
    padding-left: 40px;
    padding-right: 20px;
}

.titre {
    font-size: 3.5rem;
}

.banner-logo p {
    font-size: 1.3rem;
}
}
</style>