<template>
    <section class="partenaires">
        <div class="slideshow-container">
            <div class="slideshow">
                <div v-for="partenaire in myPartenaires" class="slide">
                    <div class="overlay" :style="{backgroundImage: `url(${partenaire.bg})`}"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {partenaires} from '@/assets/js/partenaires.js';

export default {
    
    name: 'PartenairesListMobile',
    setup() {
        let myPartenaires = partenaires;

        return {
            myPartenaires
        }
    },

    mounted() {
        const slider = document.querySelector('.slideshow-container')
        let holding = false;
        let firstClickX;
        let alreadyLeftScrolled;
        let velocity;
        let rafID;

        function startTransition(){
            stopTransition();
            rafID = requestAnimationFrame(decreasingTransition);
        }

        function stopTransition(){
            cancelAnimationFrame(rafID)
        }
        
        function decreasingTransition(){
            slider.scrollLeft += velocity;
            velocity *= 0.95;
            if(Math.abs(velocity) > 0.5){
                rafID = requestAnimationFrame(decreasingTransition)
            }
        }

        slider.addEventListener('mousedown', e => {
            holding = true;
            firstClickX = e.pageX - slider.offsetLeft;
            alreadyLeftScrolled = slider.scrollLeft; 
            stopTransition()
        })

        slider.addEventListener('mousemove', e => {
            if(!holding) return;
            const x = e.pageX - slider.offsetLeft;
            const scrolled = (x - firstClickX) * 2;
            const prevScrollLeft = slider.scrollLeft
            slider.scrollLeft = alreadyLeftScrolled - scrolled;
            velocity = slider.scrollLeft - prevScrollLeft;
        })

        slider.addEventListener('mouseup', () => {
            holding = false;
            startTransition()
        })

        slider.addEventListener('mouseleave', () => {
            holding = false;
        })

        slider.addEventListener('touchstart', e => {
            holding = true;
            // pageX => la largeur entre mon click et le DOCUMENT
            firstClickX = e.targetTouches[0].pageX - slider.offsetLeft;

            alreadyLeftScrolled = slider.scrollLeft;
            stopTransition()
        })

        slider.addEventListener('touchend', () => {
            holder = false;
            startTransition()
        })

        slider.addEventListener('touchmove', e => {
            if(!holding) return;

            const x = e.targetTouches[0].pageX - slider.offsetLeft;
            const scrolled = (x - firstClickX) * 2;
            const prevScrollLeft = slider.scrollLeft;
            slider.scrollLeft = alreadyLeftScrolled - scrolled;
            velocity = slider.scrollLeft - prevScrollLeft;
        })
    }
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
.partenaires {
    width: 100%;
    margin: 0 auto;
    margin-top: 25px;
    text-align: center;
    background-color: var(--couleurFondGris-);
}

.partenaires h1 {
    margin-top: 10px;
}

.slideshow-container {
  width: 98%;
  margin: 0 auto;
  margin-top: 2vw;
  overflow: hidden;
  min-height: 20vmin;
  position: relative;
}

.slideshow {
  position: absolute;
  height: 90%;
  display: flex;
  pointer-events: none;
}

.slide {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  height: 100%;
  width: 30vmin;
  color: #fff;
  position: relative;
  padding: 3px 10px;
  border-radius: 3px;
}

.overlay {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.slide:not(:nth-child(1)){
  margin-left: 40px;
}
}
</style>