<template>
    <article class="article partners-article flex">
        <aside>
            <img src="@/assets/images/logo/artisanat.png" alt="L'artisanat. Première entreprise de France">
        </aside>
        <div>
            <h1 class="titre-article">Nos partenaires...</h1>
            <p>
                <strong class="gras">Pro Elec 29</strong> s’associe avec les meilleurs fournisseurs du marché afin de toujours vous offrir du matériel de premier choix ainsi qu’une prestation de qualité professionnelle.
            </p>
        </div>
    </article>
</template>

<script>
export default {
    name: 'PartnersArticle',
}
</script>

<style scoped>
.partners-article {
    width: 100%;
    justify-content: space-between;
}

.partners-article aside {
    align-self: flex-start;
    padding: 30px 0px 0px 30px;
}

.partners-article div {
    padding: 60px 115px 40px 120px;
}

.partners-article div p {
    margin-top: 80px;   
}

@media screen and (max-width: 800px) {
.partners-article {
    flex-direction: column;
}

.partners-article aside {
    align-self: center;
    padding: 0px;
}

.partners-article div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 5px 40px 5px;
}

.partners-article div p {
    text-align: center;   
}
}
</style>