<template>
    <article class="article fb-article">
        <h1 class="titre-article">Suivez-nous sur les réseaux sociaux...</h1>

        <p>
            Suivez tous nos fils de discussion sur les réseaux sociaux afin de vous tenir au courant de toutes nos actualités et ainsi découvrir nos dernières nouveautés.
        </p>
        <p>
            Visitez nos pages <a class="lien-paragraphe" href="https://www.facebook.com/PRO-ELEC-29-111952791301996" target="_blank" rel="noopener" title="Facebook">Facebook</a> et <a class="lien-paragraphe" href="https://www.instagram.com/proelec29/" target="_blank" rel="noopener" title="Instagram">Instagram</a> afin de ne rater aucun événement.
        </p>
    </article>
</template>

<script>
export default {
    name: 'FbArticle',
}
</script>

<style scoped>
.fb-article {
    flex-grow: 1;
    align-self: stretch;
    padding: 70px 70px 0px 100px;
}

.fb-article p:nth-child(2) {
    margin-top: 80px;
}

.fb-article p:last-child {
    margin-top: 40px;
}

@media screen and (max-width: 1090px) {
.fb-article {
    padding: 70px 10px 10px 10px;
}

.titre-article::after {
    left: 50px;
}
}
</style>