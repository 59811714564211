<template>
    <div class="partners-grid">
        <div v-for="partenaire in myPartenaires" class="partner">
            <div class="overlay" :style="{backgroundImage: `url(${partenaire.bg})`}"></div>
        </div>
    </div>
</template>

<script>
import {partenaires} from '@/assets/js/partenaires.js';

export default {
    name: 'PartnersListDesktop',

    setup() {
        let myPartenaires = partenaires;

        return {
            myPartenaires
        }
    }
}
</script>

<style scoped>
.partners-grid {
    width: 90%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    justify-content: center;
    column-gap: 25px;
    row-gap: 35px;
}

.overlay {
    width: 150px;
    height: 120px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.partner img {
    width: 150px;
    height: 100px;
}
</style>