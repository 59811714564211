<template>
<div class="curtain-menu">
    <ul class="liste-liens">
        <li class="lien-accueil-curtain"><a class="lien" href="/" @click="toggleClass">Accueil</a></li>
        <li><a class="lien" href="/services" @click="toggleClass">Services</a></li>
        <li><a class="lien" href="/contact" @click="toggleClass">Contact</a></li>
        <li><a class="lien" href="/intervention" @click="toggleClass">Zone d'intervention</a></li>
    </ul>
</div>
</template>

<script>
export default {
    name: 'Curtain',
}
</script>

<style scoped>
.curtain-menu {
    z-index: 2;
    background-color: var(--couleurFondNoir-);
    position: absolute;
    top: 90px;
    right: -1000px;
    width: 500px;
    height: 100vh;
    padding-top: 20px;
    padding-left: 25px;
    transition: right 0.4s ease-in-out;
}

.lien {
    color: #f3f3f3;
    text-decoration: none;
    font-size: 30px;
    line-height: 2;
}

.lien:hover {
    font-weight: normal;
}

.lien.router-link-exact-active {
    text-decoration: underline var(--pantone123c-);
    text-underline-position: under;
}

.lien-accueil-curtain {
    display: none;
}

/** Animation menu burger **/
.show {
    transition: right 0.4s ease-in-out;
    right: 0px;
}

@media screen and (max-width: 800px) {
.lien-accueil-curtain {
    display: block;
}

.curtain-menu {
    width: 100vw;
}
}
</style>