<template>
    <button class="burger-box"  aria-label="menu hamburger" @click="toggleClass">
        <div class="burger">
            <div class="ligne"></div>
            <div class="ligne"></div>
            <div class="ligne"></div>
        </div>
    </button>
</template>

<script>
import {toggle} from '@/assets/js/tools.js';

export default {
    name: 'Burger',

    setup() {
        let toggleClass = toggle;

        return {
            toggleClass
        }
    }
}
</script>

<style scoped>
.burger-box {
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 5px;
    position: relative;
    background-color: var(--couleurFondBlanc-);
}

.burger {
    width: 55px;
    height: 40px;
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-event: none;
}

.ligne {
    position: absolute;
    border-radius: 10px;
    width: 100%;
    height: 7px;
    background: linear-gradient(164deg, var(--pantone123c-) 0%, var(--pantone486c-) 90%);
}

.burger-box .ligne:nth-child(1) {
    top: 0px;
}

.burger-box .ligne:nth-child(2) {
    top: 15px;
}

.burger-box .ligne:nth-child(3) {
    top: 30px;    
}

/** Animation burger **/
.burger-box .ligne:nth-child(1), .ligne:nth-child(3) {
    transform: rotate(0deg);
    transition: transform 0.4s ease-in-out, top 0.4s 0.4s ease-in-out;
}

.burger-box .ligne:nth-child(2) {
    transition: opacity 0.4s 0.4s ease-in-out;
}

.active .ligne:nth-child(1) {
    top: 15px;
    transform: rotate(45deg);
    transition: top 0.4s ease-in-out, transform 0.4s 0.4s ease-in-out;
}

.active .ligne:nth-child(2) {
    transition: opacity 0.4s ease-in-out;
    opacity: 0;
}

.active .ligne:nth-child(3) {
    top: 15px;
    transform: rotate(-45deg);
    transition: top 0.4s ease-in-out, transform 0.4s 0.4s ease-in-out;
}
</style>