<template>
  <header><Nav/></header>
  <main><router-view/></main>
  <Partners/>
  <footer><Footer/></footer>
</template>

<script>
import Nav from '@/components/main/Nav.vue';
import Partners from '@/components/main/Partners.vue';
import Footer from '@/components/main/Footer.vue';

export default {
  name: 'App',
  components: {
    Nav, Partners, Footer
  }
}
</script>

<style>
/* VARIABLES */
:root {
  --couleurFondNoir-: #333333; /* Noir adouci */
  --couleurFondGris-:#e9e9e9; /* Gris très clair */
  --couleurFondBlanc-:#f3f3f3; /* Blanc cassé */
  --couleurFondJaune-: #F7AE03; /* Jaune foncé */
  --couleurFondArticle-: #f7f7f7; /* Gris clair*/
  --couleurLienParagraphe-: #C36500; /* orange foncé */
  --pantone123c-: #fdc031; /* teinte orange logo */
  --pantone486c-: #f28576; /* teinte rose logo*/
}

/* RESET */
*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* FONT */
@font-face {
    font-family: 'robotolight';
    src: url('./assets/font/roboto-light-webfont.woff2') format('woff2'),
         url('./assets/font/roboto-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotobold';
    src: url('./assets/font/roboto-bold-webfont.woff2') format('woff2'),
         url('./assets/font/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

/* STYLE */
body {
  font-family: 'robotolight';
}

main {
  padding-top: 90px;
  min-height: 100vh;
}

footer {
  margin-top: 70px;
  background-color: var(--couleurFondNoir-);
}

/* GLOBAL */
ul {
  list-style: none;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.article,
.article-not-found {
  background-color: var(--couleurFondArticle-);
  line-height: 1.8;
  font-size: 1.1rem;
}

.titre-article {
  font-size: 2.3rem;
}

.titre-article::after {
    content: "";
    display: block;
    position: absolute;
    width: 70px;
    height: 6px;
    background: var(--couleurFondJaune-);
    margin-top: 40px;

}

.article-service,
.article-not-found {
  padding: 60px 60px 40px 90px;
}

.article-service h1,
.article-not-found h1 {
  margin-bottom: 80px;
}


.lien-paragraphe {
    text-decoration: none;
    color: var(--couleurLienParagraphe-);
}
.lien-paragraphe:hover {
    text-decoration: underline;
}

.gras {
  font-familiy: 'robotobold';
 }

@media screen and (max-width: 800px) {
body {
  background-color: var(--couleurFondArticle-);
}

.article-service {
  padding: 80px 10px 40px 10px;
}
}

</style>
