<template>
    <section class="actus flex ">
        <FbArticle/>
        <Facebook/>
    </section>
</template>

<script>
import FbArticle from '@/components/sub/actus/FbArticle.vue';
import Facebook from '@/components/sub/actus/Facebook.vue';

export default {
    name: 'Actus',

    components: {
        FbArticle, Facebook
    }
}
</script>

<style scoped>
.actus {
    margin-top: 70px;
    justify-content: space-between;
}

@media screen and (max-width: 1090px) {
.actus {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
}
</style>