<template>
  <div class="home">
    <HomeBanner/>
    <NeedElec/>
    <About/>
    <Actus/>
  </div>
</template>

<script>
import HomeBanner from '@/components/main/banners/HomeBanner.vue';
import NeedElec from '@/components/main/NeedElec.vue';
import About from '@/components/main/About.vue';
import Actus from '@/components/main/Actus.vue';

export default {
  name: 'Home',

  components: {
    HomeBanner, NeedElec, About, Actus
  }
}
</script>

<style scoped>
</style>
