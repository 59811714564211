import { createStore } from 'vuex'

export default createStore({
  state: {
    yearsEtablished: Number(new Date().getFullYear()) - Number(2017)
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
