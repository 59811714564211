<template>
    <section class="about">
        <div class="about-grid">
            <div class="west-item"></div>
            <AboutUs class="center-item"/>
            <Bilan class="south-item"/>
        </div>
    </section>
</template>

<script>
import AboutUs from '@/components/sub/about/AboutUs.vue';
import Bilan from '@/components/sub/about/Bilan.vue';

export default {
    name: 'About',

    components: {
        AboutUs, Bilan
    }
}
</script>

<style scoped>
.about-grid {
    display: grid;
    height: auto;
    width: 100%;
    grid-template-columns: 500px 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas: "west center"
                        "west south";
}

.west-item {
    grid-area: west;
    background-image: url('../../assets/images/photos/elecgen.jpg');
    background-size: cover;
    background-position: top;
}

.center-item {
    grid-area: center;
}

.south-item {
    grid-area: south;
    align-content: flex-end;
}

@media screen and (max-width: 1090px) {
.about-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 600px 1fr auto;
    grid-template-areas: "west"
                        "center"
                        "south";
}
}
</style>