import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'

const prefixe = 'Pro Elec 29 | ';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: prefixe.concat('Accueil')
    }
  },
  {
    path: '/intervention',
    name: 'Intervention',
    component: () => import(/* webpackChunkName: "intervention" */ '@/views/Intervention.vue'),
    meta: {
      title: prefixe.concat('Zone d\'intervention')
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue'),
    meta: {
      title: prefixe.concat('Contact')
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import(/* webpackChunkName: "services" */ '@/views/Services.vue'),
    meta: {
      title: prefixe.concat('Services')
    },
    children: [
      {
        path: '/services',
        name: 'Accueil',
        component: () => import(/* webpackChunkName: "accueil" */ '@/views/services/Accueil.vue'),
        meta: {
          title: prefixe.concat('Services - Accueil')
        }       
      },
      {
        path: '/services/electricite',
        name: 'Electricite',
        component: () => import(/* webpackChunkName: "electricite" */ '@/views/services/Electricite.vue'),
        meta: {
          title: prefixe.concat('Services - Electricité')
        }       
      },
      {
        path: '/services/depannage',
        name: 'Depannage',
        component: () => import(/* webpackChunkName: "depannage" */ '@/views/services/Depannage.vue'),
        meta: {
          title: prefixe.concat('Services - Dépannage')
        }       
      },
      {
        path: '/services/domotique',
        name: 'Domotique',
        component: () => import(/* webpackChunkName: "domotique" */ '@/views/services/Domotique.vue'),
        meta: {
          title: prefixe.concat('Services - Domotique')
        }       
      },
      {
        path: '/services/borne',
        name: 'Borne',
        component: () => import(/* webpackChunkName: "borne" */ '@/views/services/Borne.vue'),
        meta: {
          title: prefixe.concat('Services - Borne de charge')
        }       
      },
      {
        path: '/services/led',
        name: 'Led',
        component: () => import(/* webpackChunkName: "led" */ '@/views/services/Led.vue'),
        meta: {
          title: prefixe.concat('Services - LED')
        }       
      },
      {
        path: '/services/magasin',
        name: 'Magasin',
        component: () => import(/* webpackChunkName: "magasin" */ '@/views/services/Magasin.vue'),
        meta: {
          title: prefixe.concat('Services - Amélioration magasin')
        }       
      },
      {
        path: '/services/vmc',
        name: 'Vmc',
        component: () => import(/* webpackChunkName: "vmc" */ '@/views/services/Vmc.vue'),
        meta: {
          title: prefixe.concat('Services - VMC')
        }       
      }
    ]
  },
  {
    path: '/mentions',
    name: 'Legale',
    component: () => import(/* webpackChunkName: "legale" */ '@/views/Legale.vue'),
    meta: {
      title: prefixe.concat('Mentions légales')
    }
  },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '@/views/NotFound.vue'),
    meta: {
      title: prefixe.concat('Page introuvable')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.afterEach((to) => {
  document.title = to.meta.title;
});

export default router
