<template>
    <section class="need-elec flex">
        <h3>Besoin d'un électricien ?</h3>
        <a href="tel:+33784952929" title="Appelez-nous">07 84 95 29 29</a>
    </section>
</template>

<script>
export default {
    name: 'NeedElec',
}
</script>

<style scoped>
.need-elec {
    margin: 0 auto;
    padding: 50px 0px;
    column-gap: 200px;
    flex-wrap: wrap;
}

.need-elec h3 {
    font-size: clamp(50px, 3vw, 100px);
    color: var(--couleurFondNoir-);
    font-weight: bolder;
}

.need-elec a {
    position: relative;
    font-family: 'robotobold';
    text-decoration: none;
    font-size: max(3.2vw, 35px);
    padding: 10px 50px;
    border-radius: 30px;
    color: var(--couleurFondJaune-);
    background-color: var(--couleurFondNoir-);
    transition: color 0.4s, background-color 0.4s ease-in;
}

.need-elec a:hover {
    color: var(--couleurFondNoir-);
    background-color: var(--couleurFondJaune-);
}

@media screen and (max-width: 800px) {
.need-elec {
    width: 95vw;
    column-gap: 0px;
    row-gap: 20px;
}

.need-elec h3 {
    font-size: 6vw;
    padding-top: 15px;
}
}
</style>