const  chemin = "/assets/images/logos/partenaires/";

export let partenaires = [
    {
        nom: "Art d'Arnould",
        bg: chemin.concat("arnould.jpg"),
        link: "https://www.legrand.fr/prises-et-interrupteurs-arnould-des-solutions-design-et-innovantes",
        class: "paysage",
    },
    {
        nom: "Atlantic",
        bg: chemin.concat("atlantic.png"),
        link: "https://www.atlantic.fr/",
        class: "paysage",
    },
    {
        nom: "Cables Industriels",
        bg: chemin.concat("cables_industriels.png"),
        link: "",
        class: "paysage",
    },
    {
        nom: "Came",
        bg: chemin.concat("came.png"),
        link: "https://www.came-europe.com/",
        class: "paysage",
    },
    {
        nom: "Carrier",
        bg: chemin.concat("carrier.png"),
        link: "https://www.carrier.com/commercial/fr/fr/",
        class: "paysage",
    },
    {
        nom: "Courant faibles intensité",
        bg: chemin.concat("courant_fes.png"),
        link: "",
        class: "paysage",
    },
    {
        nom: "Eaton",
        bg: chemin.concat("eaton.png"),
        link: "https://www.eaton.com/fr/fr-fr.html",
        class: "paysage",
    },
    {
        nom: "Eris",
        bg: chemin.concat("eris.png"),
        link: "https://www.eaton.com/fr/fr-fr.html",
        class: "paysage",
    },
    {
        nom: "Evicom",
        bg: chemin.concat("evicom.jpg"),
        link: "https://www.evicom.fr/",
        class: "paysage",
    },
    {
        nom: "In house Led",
        bg: chemin.concat("in_house.png"),
        link: "",
        class: "paysage",
    },
    {
        nom: "L2S",
        bg: chemin.concat("l2s.jpg"),
        link: "https://l2s.centralesupelec.fr/",
        class: "paysage",
    },
    {
        nom: "Ledvance",
        bg: chemin.concat("ledvance.png"),
        link: "https://www.ledvance.fr/",
        class: "paysage",
    },
    {
        nom: "Luxna lightning",
        bg: chemin.concat("luxna.png"),
        link: "https://www.luxna-lighting.fr/",
        class: "paysage",
    },
    {
        nom: "Obo",
        bg: chemin.concat("obo.png"),
        link: "https://obo.fr/fr-fr",
        class: "paysage"
    },
    {
        nom: "Osily Energy",
        bg: chemin.concat("osily_energy.jpg"),
        link: "https://www.osily.fr/",
        class: "paysage"
    },
    {
        nom: "Osily",
        bg: chemin.concat("osily.png"),
        link: "https://www.osily.fr/",
        class: "paysage"
    },
    {
        nom: "Schneider",
        bg: chemin.concat("schneider.png"),
        link: "https://www.se.com/fr/fr/",
        class: "paysage"
    },
    {
        nom: "SLV",
        bg: chemin.concat("slv.png"),
        link: "https://www.slv.com/fr_fr/",
        class: "paysage"
    },
    {
        nom: "Sylvania",
        bg: chemin.concat("sylvania.png"),
        link: "https://www.sylvania-lighting.com/fr-fr/",
        class: "paysage"
    },
    {
        nom: "Systorm",
        bg: chemin.concat("systorm.png"),
        link: "https://www.l2s-systorm.fr/",
        class: "paysage"
    },
    {
        nom: "Wireplast",
        bg: chemin.concat("wireplast.png"),
        link: "https://www.l2s-systorm.fr/",
        class: "paysage"
    },
    {
        nom: "Your Essentials",
        bg: chemin.concat("ye.png"),
        link: "https://www.l2s-systorm.fr/",
        class: "paysage"
    }
];