<template>
    <section class="home-banner flex">
        <div class="left-banner"><BannerLogo/></div>
        <div class="right-banner"><BannerServices/></div>
    </section>
</template>

<script>
import BannerLogo from '@/components/sub/banners/homeBanner/BannerLogo.vue';
import BannerServices from '@/components/sub/banners/homeBanner/BannerServices.vue';

export default {
    name: 'HomeBanner',

    components: {
        BannerLogo, BannerServices
    }
}
</script>

<style scoped>
.home-banner {
    justify-content: space-between;
    width: 100%;
    min-height: 600px;
    background-color: var(--couleurFondJaune-);
    /*background: linear-gradient(105deg, rgba(51,51,51,1) 47%, rgba(253,144,49,1) 100%);
    background: linear-gradient(105deg, rgba(51,51,51,1) 50%, #F7AE03 70%,  #F7AE03 100%);*/
    padding-right: 50px;
}

.left-banner {
    flex-grow: 1;
    align-self: stretch;
    clip-path: polygon(0% 0%, 100% 0%, 95% 100%, 0% 100%);
}

.right-banner {
    align-content: left;
    padding-left: 45px;
    max-width:420px;
    width: 35%;
}

@media screen and (max-width: 800px) {
.home-banner {
    flex-direction: column;
}

.left-banner {
    width: 100vw;
    height: 600px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 95%);
}

.right-banner {
    padding-left: 30px;
    padding-bottom: 10px;
    width: 100vw;
}
}
</style>