<template>
    <div class="bloc-footer">

        <div class="bloc footer-logo">
            <img src="@/assets/images/logo/pe29/pro-elec-29_logo-V_Q-blanc.png" alt="Logo de l'entreprise">
        </div>

        <div class="bloc footer-contact">
            <h3>Nous contacter</h3>
            <p>07 84 95 29 29</p>
            <p>36 Rue de l'étain, 29290 Saint Renan</p>
            <p>contact@proelec29.com</p>
        </div>

        <div class="bloc footer-horaires">
            <h3>Nos horaires</h3>
            <ul class="liste-horaire">
                <li>✔️ Mar 08h-19h</li>
                <li>✔️ Mer 08h-19h</li>
                <li>✔️ Jeu 08h-19h</li>
                <li>✔️ Ven 08h-19h</li>
                <li>✔️ Lun 08h-19h</li>
                <li>❌ Sam fermé</li>
                <li>❌ Dim fermé</li>
            </ul>
        </div>

        <div class="bloc footer-reseaux">
            <h3>Nos réseaux</h3>
            <ul class="liste-media">
                <li><a href="https://www.facebook.com/PRO-ELEC-29-111952791301996" target="_blank" rel="noopener" title="Facebook"><img src="@/assets/images/logo/reseaux/facebook.svg" alt="icone facebook"></a></li>
                <li><a href="https://www.instagram.com/proelec29/" target="_blank" rel="noopener" title="Instagram"><img src="@/assets/images/logo/reseaux/instagram.svg" alt="icone instagram"></a></li>
                <li><a href="https://fr.linkedin.com/in/fr%C3%A9d%C3%A9ric-bonmartin-17114b163" rel="noopener" target="_blank" title="Linkedin"><img src="@/assets/images/logo/reseaux/linkedin.svg" alt="icone linkedin"></a></li>
            </ul>
        </div>
    </div>

    <div class="footer-copyright">
        <p>Site hébergé par IONOS</p>
        <small>&copy;2021 - Pro Elec 29 | <a href="/mentions">Mentions légales</a></small>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
.bloc-footer {
    margin: 0 auto;
    width: 100%;
    border-top: dashed 1px #030303;
    padding-top: 15px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    color: #d3d3d3;
}

.bloc h3,
.bloc p,
.bloc li {
    padding: 2px;
}

.bloc h3 {
    text-decoration: underline;
    padding-bottom: 10px;
}

.footer-copyright {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: #d3d3d3;
    padding: 40px 0px 10px 0px;
}

.footer-copyright a {
    color: #d3d3d3;
    text-decoration: none;
}

.footer-copyright a:hover {
    font-weight: normal;
    font-style: italic;
}

.liste-media img {
    width: 40px;
}

.liste-media li {
    display: inline-block;
    margin-top: 10px;
}

.liste-media li:not(:first-child) {
    margin-left: 25px;
}

@media screen and (max-width: 768px) {
.bloc-footer {
    flex-direction: column;
    text-align: center;
    row-gap: 20px;
}

.bloc {
    width: 100%;
    margin: 0;
}

.footer-copyright {
    padding: 0px 0px 10px 0px;
}

.liste-media li {
    margin-top: 5px;
}

.footer-copyright {
    margin-top: 8px;
}

}
</style>