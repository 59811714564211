<template>
    <nav class="nav flex">
        <div class="nav-logo">
            <img src="@/assets/images/logo/pe29/pro-elec-29_logo_petit.png" alt="logo proelec29">
        </div>
        
        <div class="nav-lien flex">
            <a class="lien-accueil-desktop" href="/">Accueil</a>
            <Burger />
            <Curtain />
        </div>
    </nav>
</template>

<script>
import Burger from '@/components/sub/nav/Burger.vue';
import Curtain from '@/components/sub/nav/Curtain.vue'

export default {
    name: 'Nav',

    components: {
        Burger, Curtain
    }
}
</script>

<style scoped>
.nav {
    z-index: 2;
    position: fixed;
    width: 100%;
    height: 90px;
    justify-content: space-between;
    background-color: var(--couleurFondBlanc-);
    padding: 0px 30px;
}

.nav-lien {
    column-gap: 40px;
}

.lien-accueil-desktop {
    display: block;
    text-decoration: none;
    color: var(--couleurFondNoir-);
    font-size: 1.3rem;
}

@media screen and (max-width: 800px) {
.nav {
    padding: 0px 10px;;
}

.lien-accueil-desktop {
    display: none;
}
}
</style>